import React from 'react'
import { LayoutTypeView } from '@api/endpoints/buildings/layoutTypes'

type LayoutTypeFormContext = {
  layoutType: LayoutTypeView
}

export const LayoutTypeFormContext = React.createContext({} as LayoutTypeFormContext)

export const useLayoutTypeFormContext = (): LayoutTypeFormContext => React.useContext(LayoutTypeFormContext)
